import React from 'react'
import PropTypes from 'prop-types'
import marked from 'marked'

// import styled from 'styled-components'
// import theme from 'theme'
//
// import renderTemplate from 'shared/render-template'
//
// const Mark = styled.div`
//   pre {
//     width: 100%;
//     padding: 12px 15px;
//     overflow: auto;
//
//     white-space: pre;
//     color: white;
//     background: ${theme.colors.black};
//   }
//
//   strong {
//     font-weight: 600;
//   }
//
//   img {
//     max-width: 100%;
//   }
//
//   hr:first-child {
//     margin-top: 20px !important;
//   }
//   hr:last-child {
//     margin-bottom: 20px !important;
//   }
// `

const Markdown = ({ children }) => {
  const markedOptions = {
    sanitize: true,
    breaks: true,
  }
  return (
    <div className="markdown" dangerouslySetInnerHTML={{
      __html: marked(children, markedOptions),
    }} />
  )
}

Markdown.defaultProps = {
}

Markdown.propTypes = {
  children: PropTypes.string.isRequired,
}

export default Markdown
