import React from 'react'
import styled from 'styled-components'
import { Link, graphql } from 'gatsby'

import _ from 'lodash'

import AltBio from '../components/AltBio'
import Header from '../components/Header'
import Container from '../components/Container'
import Helmet from '../components/Helmet'
import Tags from '../components/Tags'
import ColoredSpan from '../components/ColoredSpan'
import Markdown from '../components/Markdown'

import typography from '../utils/typography'
import media from '../utils/media'

const BlogPostContainer = styled.div`
  font-size: .9rem;

  h1 {
    line-height: 1.3;
    font-size: 2rem;
    margin: ${typography.rhythm(1)} 0;
  }

  ${media.desktop`
    font-size: 1rem;
  `}
`

const SubtleText = styled.span`
  color: rgba(0,0,0, .4);
`

const TagsContainer = styled.p`
  font-size: 16px;
  font-style: italic;
  text-align: ${({ textAlign }) => textAlign}
`

const HeroContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: -80px;
  margin-bottom: 40px;
  padding-bottom: 75%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ${media.desktop`
    height: 100vh;
    padding-bottom: 0;
  `}
`

const ShowInDesktop = styled.div`
  display: none;
  ${media.desktop`
    display: block;
  `}
`

const HideInDesktop = styled.div`
  display: block;
  ${media.desktop`
    display: none;
  `}
`

const HeroContent = styled.div`
  position: absolute;
  bottom: 5vw;
  left: 5vw;
  color: white;
  max-width: 40%;

  h1 {
    margin-top: 0;
    line-height: 1.3;
    font-size: 46px;
  }

  p:last-child {
    margin: 0;
  }

  p a {
    color: inherit;
    font-weight: bold;
    text-decoration: none;
    box-shadow: none;

    &:hover {
      text-decoration: underline;
    }
  }
`

const Underline = styled.div`
  height: 2px;
  width: 80px;
  margin: 40px 0 45px;
  background: white;
  opacity: .6;
`

function linkify (line) {
  const mention = /(@[^\s]+)/
  const fragments = line.split(mention)
  return _.map(fragments, (f) => (
    mention.test(f)
      ? (<a key={line + f} href={`https://instagram.com/${f.slice(1)}`} target="__blank">{f}</a>)
      : f
  ))
}

function isSingleChar (str) {
  return str.length === 1 ||
    (str.length === 2 && str.codePointAt(0) > 255)
}

function timesChar (times, char) {
  return _.times(times, () => (char)).join('')
}

const ColoredTitle = ({ colors, title }) => {
  const { title: color, titleBackground: background } = colors

  const lines = title.replace(/[ ]([^ ]+)/g, function (match, match1) {
    return isSingleChar(match1) ? ` ${match1}` : `\n${match1}`
  }).replace(/\|/g, ' ').split('\n')

  return (
    <h1>
      {_.map(lines, (l, i) => (
        <ColoredSpan key={i + l} {...{ color, background }} style={{ padding: '0 5px' }}>
          {l}
        </ColoredSpan>
      ))}
    </h1>
  )
}

const ColoredBody = ({ colors, paragraphs }) => {
  const { body: color, bodyBackground: background } = colors
  return (
    <div>
      {_.map(paragraphs, (lines, i) => (
        <p key={i}>
          {_.map(lines, (l, i) => (
            <ColoredSpan key={i + l} {...{ color, background }}>
              {linkify(l)}
            </ColoredSpan>
          ))}
        </p>
      ))}
    </div>
  )
}

const Notes = ({ notes }) => {
  if (!notes || notes.length === 0) return null

  return (
    <div className="">
      {_.map(notes, (note, i) => {
        const noteChar = timesChar(i + 1, '*')
        return <Markdown key={`note${i}`}>{`${'\\'}${noteChar} ${note}`}</Markdown>
      })}
    </div>
  )
}

class BlogPostTemplate extends React.Component {
  render () {
    const post = this.props.data.markdownRemark
    const { previous, next } = this.props.pathContext

    const heroSizes = _.get(post, 'frontmatter.image.childImageSharp.fluid')
    const { title, description, tags, originalPost, notes } = post.frontmatter
    const colors = post.frontmatter.colors || {}

    const paragraphs = this.props.pathContext.paragraphs
    // const paragraphs = [[description]].concat(this.props.pathContext.paragraphs)

    return (
      <BlogPostContainer>
        <Header color={colors.header} />
        <Helmet
          siteMetadata={this.props.data.site.siteMetadata}
          title={`${title} Cocktail`}
          description={description}
          image={heroSizes}
        />

        <HeroContainer style={{ backgroundImage: `url('${heroSizes.src}')` }}>
          <ShowInDesktop>
            <HeroContent>
              <ColoredTitle {...{ colors, title }} />
              <Underline style={{ background: colors.body }} />
              <ColoredBody {...{ colors, paragraphs }} />
            </HeroContent>
          </ShowInDesktop>
        </HeroContainer>

        <Container>
          <ShowInDesktop>
            <TagsContainer textAlign="center">
              <SubtleText>
                filed in <Tags tags={tags} />.
                see the <a href={originalPost}>original post &rarr;</a>
              </SubtleText>
            </TagsContainer>
          </ShowInDesktop>

          <HideInDesktop>
            <h1>{post.frontmatter.title}</h1>
            <p>{description}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />

            <TagsContainer textAlign="left">
              <SubtleText>
                filed in <Tags tags={tags} />.
                see the <a href={originalPost}>original post &rarr;</a>
              </SubtleText>
            </TagsContainer>

            <hr style={{ marginBottom: typography.rhythm(1) }} />
          </HideInDesktop>

          <Notes notes={notes} />
          <AltBio />

          <ul
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              listStyle: 'none',
              padding: 0,
              margin: 0,
            }}
          >
            {previous && (
              <li>
                <Link to={previous.fields.slug} rel="prev">
                  ← {previous.frontmatter.title}
                </Link>
              </li>
            )}

            {next && (
              <li>
                <Link to={next.fields.slug} rel="next">
                  {next.frontmatter.title} →
                </Link>
              </li>
            )}
          </ul>
        </Container>
      </BlogPostContainer>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      ...HelmetFragment
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      ...PostFragment
      ...PostFullImageFragment
    }
  }
`
